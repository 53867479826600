import { useMemo, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client";
import { chain, sumBy, sortBy, maxBy, minBy } from "lodash";
import fetch from "cross-fetch";
import * as ethers from "ethers";

import { fillPeriods } from "./helpers";
import { addresses, getAddress, MODE_MAINNET } from "./addresses";

const BigNumber = ethers.BigNumber;
const formatUnits = ethers.utils.formatUnits;
const { JsonRpcProvider } = ethers.providers;

import RewardReader from "../abis/RewardReader.json";
import LlpManager from "../abis/LlpManager.json";
import Token from "../abis/v1/Token.json";

const providers = {
  mode_mainnet: new JsonRpcProvider("https://mainnet.mode.network"),
};

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return providers[chainName];
}

function getChainId(chainName) {
  const chainId = {
    mode_mainnet: MODE_MAINNET,
  }[chainName];
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return chainId;
}

const DEFAULT_GROUP_PERIOD = 86400;
const NOW_TS = parseInt(Date.now() / 1000);
const FIRST_DATE_TS = parseInt(+new Date(2022, 11, 21) / 1000);

function fillNa(arr) {
  const prevValues = {};
  let keys;
  if (arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}

export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const rewardReader = new ethers.Contract(
    getAddress(chainId, "RewardReader"),
    RewardReader.abi,
    provider
  );
  const llpContract = new ethers.Contract(
    getAddress(chainId, "LLP"),
    Token.abi,
    provider
  );
  const llpManager = new ethers.Contract(
    getAddress(chainId, "LlpManager"),
    LlpManager.abi,
    provider
  );

  let depositTokens;
  let rewardTrackersForDepositBalances;
  let rewardTrackersForStakingInfo;

  if (chainId === MODE_MAINNET) {
    depositTokens = [
      // "0x6cADe7E303D3Ad722cD5d3f2569A8d139b727F91", //LIQ
      // "0xcd8F6f6B77DFD1298a0AEccbAab41270f51B4f35", //esLIQ
      // "0xD5B365DFf901f22Cb42ce4ab7c815e942D45b09b", //stakedLIQTracker
      // "0xD9bcC8D2730441eE44785a6a867C5d6f8765C6de", // Staked + Bonus LIQ sbLIQ   bonusLiqTracker
      // "0x76f31F9506fdE8BB88E53460FDA2268e24C510D9", // bnLIQ
      "0xd0de0b899cdded68bef186c5637a38c4a95dff39", // LLP
    ];
    rewardTrackersForDepositBalances = [
      // "0xD5B365DFf901f22Cb42ce4ab7c815e942D45b09b", //stakedLIQTracker
      // "0xD5B365DFf901f22Cb42ce4ab7c815e942D45b09b", //stakedLIQTracker
      // "0xD9bcC8D2730441eE44785a6a867C5d6f8765C6de", // Staked + Bonus LIQ sbLIQ  bonusLiqTracker
      // "0xdc6a9f7f6803610Ce7E366Ab5Fdca14bb24d7Dbb", //  Staked + Bonus + Fee LIQ (sbfLIQ) //feeLiqTracker
      // "0xdc6a9f7f6803610Ce7E366Ab5Fdca14bb24d7Dbb", //Staked + Bonus + Fee LIQ(sbfLIQ) //feeLiqTracker
      "0x046cd43203fafd94b5c2c19e20641265662fa66e", // feeLLP //feeLlpTracker
    ];
    rewardTrackersForStakingInfo = [
      // "0xD5B365DFf901f22Cb42ce4ab7c815e942D45b09b", //stakedLIQTracker
      // "0xD9bcC8D2730441eE44785a6a867C5d6f8765C6de", // Staked + Bonus LIQ sbLIQ
      // "0xdc6a9f7f6803610Ce7E366Ab5Fdca14bb24d7Dbb", //  Staked + Bonus + Fee LIQ (sbfLIQ) //feeLiqTracker
      // "0x164a6453d65a601ce2c89CAC56b13Da7Bc1d4051", // Fee + Staked LLP (fsLLP) // stakedLlpTracker
      "0x046cd43203fafd94b5c2c19e20641265662fa66e", // feeLLP //feeLlpTracker
    ];
  } else {
    // depositTokens = [
    //   "0x62edc0692BD897D2295872a9FFCac5425011c661", //LIQ
    //   "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",//esLIQ
    //   "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",//stakedLIQ
    //   "0x908C4D94D34924765f1eDc22A1DD098397c59dD4", //Staked + Bonus LIQ(sbLIQ)
    //   "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2", //Bonus LIQ (bnLIQ)
    //   "0x01234181085565ed162a948b6a5e88758CD7c7b8", // LIQ LP (LLP)
    // ];
    // rewardTrackersForDepositBalances = [
    //   "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342", //stakedLIQ
    //   "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",//stakedLIQ
    //   "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",//Staked + Bonus LIQ(sbLIQ)
    //   "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",// Staked + Bonus + Fee LIQ (sbfLIQ)
    //   "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",// Staked + Bonus + Fee LIQ (sbfLIQ)
    //   "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",//Fee LLP (fLLP)
    // ];
    // rewardTrackersForStakingInfo = [
    //   "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",//stakedLIQ
    //   "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",//Staked + Bonus LIQ(sbLIQ)
    //   "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",//  Staked + Bonus + Fee LIQ (sbfLIQ)
    //   "0x9e295B5B976a184B14aD8cd72413aD846C299660", // Fee + Staked LLP (fsLLP)
    //   "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",//Fee LLP (fLLP)
    // ];
  }

  const [
    balances,
    stakingInfo,
    rewardTokensAddresses,
    llpTotalSupply,
    llpAum /*liqPrice*/,
  ] = await Promise.all([
    rewardReader.getDepositBalances(
      account,
      depositTokens,
      rewardTrackersForDepositBalances
    ),
    rewardReader
      .getStakingInfo(account, rewardTrackersForStakingInfo)
      .then((info) => {
        return rewardTrackersForStakingInfo.map((_, i) => {
          return info[0].slice(i * 5, (i + 1) * 5);
        });
      }),
    rewardReader
      .getStakingInfo(account, rewardTrackersForStakingInfo)
      .then((info) => {
        return info[1];
      }),
    llpContract.totalSupply(),
    llpManager.getAumInUsdl(true),
    // fetch(
    //   "https://api.coingecko.com/api/v3/simple/price?ids=liq&vs_currencies=usd"
    // ).then(async (res) => {
    //   const j = await res.json();
    //   return j["liq"]["usd"];
    // }),
  ]);

  const llpPrice = llpAum / 1e18 / (llpTotalSupply / 1e18);
  const now = new Date();

  return {
    LLP: {
      //stakedLLP: balances[5] / 1e18,
      stakedLLP: balances[0] / 1e18,
      pendingETH: stakingInfo[0][0] / 1e18,
      //pendingEsLIQ: stakingInfo[3][0] / 1e18,
      llpPrice,
    },
    // LIQ: {
    //   stakedLIQ: balances[0] / 1e18,
    //   stakedEsLIQ: balances[1] / 1e18,
    //   pendingETH: stakingInfo[2][0] / 1e18,
    //   pendingEsLIQ: stakingInfo[0][0] / 1e18,
    //   liqPrice,
    // },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString(),
  };
}

export const tokenSymbols = {
  "0xcdd475325d6f564d27247d1dddbb0dac6fa0a5cf": "BTC",
  "0x4200000000000000000000000000000000000006": "ETH",
  "0xd988097fb8612cc24eec14542bc03424c656005f": "USDC",
  "0xf0f161fda2712db8b566946122a5af183995e2ed": "USDT",
};

const knownSwapSources = {
  mode_mainnet: {
    "0x0fde376d8b1fb121b65d496105dff4c13f4d0b4b": "Liq Router", // Router
    "0xcbff07161bee36f38a55caeaccd9b4bcdbdd9c1f": "Liq Orderbook", // Orderbook
    "0x59719c598d21026d8f79553ede552400bd50f9a4": "Liq Position Manager", // PositionManager
    "0x705a9cceedeab1dca662fccf72cc5000b80572d8": "Liq OrderKeeper", // OrderKeeper
    "0x3180341afdd106f14d224ec96c9a17420ab5f33d": "Liq Fast PriceFeed", // FastPriceFeed
    // "0x3b6067d4caa8a14c63fdbe6318f27a0bbc9f9237": "Dodo",
    // "0x11111112542d85b3ef69ae05771c2dccff4faa26": "1inch",
    // "0x6352a56caadc4f1e25cd6c75970fa768a3304e64": "OpenOcean", // OpenOceanExchangeProxy
    // "0x4775af8fef4809fe10bf05867d2b038a4b5b2146": "Gelato",
    // "0x5a9fd7c39a6c488e715437d7b1f3c823d5596ed1": "LiFiDiamond",
    // "0x1d838be5d58cc131ae4a23359bc6ad2dddb8b75a": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    // "0xc4bed5eeeccbe84780c44c5472e800d3a5053454": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    // "0xe40beb54ba00838abe076f6448b27528dd45e4f0": "Vovo", // Vovo BTC UP USDC (vbuUSDC)
    // "0x9ba57a1d3f6c61ff500f598f16b97007eb02e346": "Vovo", // Vovo ETH UP USDC (veuUSDC)
    // "0xfa82f1ba00b0697227e2ad6c668abb4c50ca0b1f": "JonesDAO",
    // "0x226cb17a52709034e2ec6abe0d2f0a9ebcec1059": "WardenSwap",
    // "0x1111111254fb6c44bac0bed2854e76f90643097d": "1inch",
    // "0x6d7a3177f3500bea64914642a49d0b5c0a7dae6d": "deBridge",
    // "0xc30141b657f4216252dc59af2e7cdb9d8792e1b0": "socket.tech",
  },
};

const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url]);

  return [data, loading, error];
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: "bitcoin",
    ETH: "ethereum",
    LINK: "chainlink",
    UNI: "uniswap",
    AVAX: "avalanche-2",
    MATIC: "matic-network",
  }[symbol];

  const now = Date.now() / 1000;
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  const [res, loading, error] = useRequest(url);

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for MLP we store last price of the day
      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;
      return {
        timestamp: groupTs,
        value: item[1],
      };
    });
    return ret;
  }, [res]);

  return [data, loading, error];
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}

function getChainSubgraph(chainName) {
  return chainName === "mode_mainnet" ? "liq-stats/1.0.0/gn" : "";
}

export function useGraph(
  querySource,
  { subgraph = null, subgraphUrl = null, chainName = "mode_mainnet" } = {}
) {
  const query = gql(querySource);

  if (!subgraphUrl) {
    if (!subgraph) {
      subgraph = getChainSubgraph(chainName);
    }
    subgraphUrl = `https://api.goldsky.com/api/public/project_clrhmyxsvvuao01tu4aqj653e/subgraphs/${subgraph}`;
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn(
          "Subgraph request failed error: %s subgraphUrl: %s",
          ex.message,
          subgraphUrl
        );
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

// export function useGraphTest(
//   querySource,
//   { subgraph = null, subgraphUrl = null, chainName = "mode_mainnet" } = {}
// ) {
//   const query = gql(querySource);

//   if (!subgraphUrl) {
//     // if (!subgraph) {
//     //   subgraph = getChainSubgraph(chainName);
//     // }
//     subgraphUrl = `https://graph.testnet.mantle.xyz/subgraphs/name/fusionx-perp/stats`;
//   }

//   const client = new ApolloClient({
//     link: new HttpLink({ uri: subgraphUrl, fetch }),
//     cache: new InMemoryCache(),
//   });
//   const [data, setData] = useState();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     setLoading(true);
//   }, [querySource, setLoading]);

//   useEffect(() => {
//     client
//       .query({ query })
//       .then((res) => {
//         setData(res.data);
//         setLoading(false);
//       })
//       .catch((ex) => {
//         console.warn(
//           "Subgraph request failed error: %s subgraphUrl: %s",
//           ex.message,
//           subgraphUrl
//         );
//         setError(ex);
//         setLoading(false);
//       });
//   }, [querySource, setData, setError, setLoading]);

//   return [data, loading, error];
// }

export function useLastBlock(chainName = "mode_mainnet") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName = "mode_mainnet") {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainName }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useTradersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainName }
  );
  const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {};
    }

    let feesCumulative = 0;
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map(
        (dataItem) => {
          const longOpenInterest = dataItem.longOpenInterest / 1e30;
          const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
          const openInterest = longOpenInterest + shortOpenInterest;

          // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
          // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

          const profit = dataItem.profit / 1e30;
          const loss = dataItem.loss / 1e30;
          const profitCumulative = dataItem.profitCumulative / 1e30;
          const lossCumulative = dataItem.lossCumulative / 1e30;
          const pnlCumulative = profitCumulative - lossCumulative;
          const pnl = profit - loss;
          currentProfitCumulative += profit;
          currentLossCumulative -= loss;
          currentPnlCumulative += pnl;
          return {
            longOpenInterest,
            shortOpenInterest,
            openInterest,
            profit,
            loss: -loss,
            profitCumulative,
            lossCumulative: -lossCumulative,
            pnl,
            pnlCumulative,
            timestamp: dataItem.timestamp,
            currentPnlCumulative,
            currentLossCumulative,
            currentProfitCumulative,
          };
        }
      )
    : null;

  if (data && data.length) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `;
}
export function useSwapSources({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    );

    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source =
              knownSwapSources[chainName][item.source] || item.source;
            if (!topVolumeSources.has(source)) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;
              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest(
    "https://liq-server-mainnet.uw.r.appspot.com/total_volume"
  );

  return useMemo(() => {
    if (!data) {
      return [data, loading];
    }

    const total = data.reduce((memo, item) => {
      return memo + parseInt(item.data.volume) / 1e30;
    }, 0);
    return [total, loading];
  }, [data, loading]);
}

function getServerHostname(chainName) {
  if (chainName == "mode_mainnet") {
    return "stats.liq.markets";
  }
  return "liq-server-mainnet.uw.r.appspot.com";
}

export function useVolumeDataRequest(
  url,
  defaultValue,
  from,
  to,
  fetcher = defaultFetcher
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url, from, to]);

  return [data, loading, error];
}

export function useVolumeDataFromServer({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const [data, loading] = useVolumeDataRequest(
    `https://${getServerHostname(chainName)}/daily_volume`,
    null,
    from,
    to,
    async (url) => {
      let after;
      const ret = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const res = await (
          await fetch(url + (after ? `?after=${after}` : ""))
        ).json();
        if (res.length === 0) return ret;
        for (const item of res) {
          if (item.data.timestamp < from) {
            return ret;
          }
          ret.push(item);
        }
        after = res[res.length - 1].id;
      }
    }
  );

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp;
      if (timestamp < from || timestamp > to) {
        return memo;
      }

      let type;
      if (item.data.action === "Swap") {
        type = "swap";
      } else if (item.data.action === "SellUSDL") {
        type = "burn";
      } else if (item.data.action === "BuyUSDL") {
        type = "mint";
      } else if (item.data.action.includes("LiquidatePosition")) {
        type = "liquidation";
      } else {
        type = "margin";
      }
      const volume = Number(item.data.volume) / 1e30;
      memo[timestamp] = memo[timestamp] || {};
      memo[timestamp][type] = memo[timestamp][type] || 0;
      memo[timestamp][type] += volume;
      return memo;
    }, {});

    let cumulative = 0;
    const cumulativeByTs = {};
    return Object.keys(tmp)
      .sort()
      .map((timestamp) => {
        const item = tmp[timestamp];
        let all = 0;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        PROPS.forEach((prop) => {
          if (item[prop]) all += item[prop];
        });
        cumulative += all;
        cumulativeByTs[timestamp] = cumulative;
        return {
          timestamp,
          all,
          cumulative,
          movingAverageAll,
          ...item,
        };
      });
  }, [data, from, to]);

  return [ret, loading];
}

export function useUsersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce(
          (memo, type) => {
            memo[`new${type}Count`] = prevUniqueCountCumulative[type]
              ? item[`unique${type}CountCumulative`] -
                prevUniqueCountCumulative[type]
              : item[`unique${type}Count`];
            prevUniqueCountCumulative[type] =
              item[`unique${type}CountCumulative`];
            return memo;
          },
          {}
        );
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum:
            item.uniqueSwapCount +
            item.uniqueMarginCount +
            item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useFundingRateData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token];
      if (symbol === "MIM") {
        return memo;
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta =
        parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"));
  }, [graphData]);

  return [data, loading, error];
}

const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

export function useVolumeData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp = chainName === "mode_mainnet" ? "id" : "timestamp";
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join("\n")}
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll =
          (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  return [data, loading, error];
}

export function useFeesData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      ${chainName === "avax" ? "timestamp" : ""}
    }
  }`;
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName,
  });

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);

  return [feesChartData, loading, error];
}

export function useFeesDataByUser({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
  user,
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to}, address: ${user} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
      ${chainName === "avax" ? "timestamp" : ""}
    }
  }`;
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName,
  });

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);

  return [feesChartData, loading, error];
}

export function useAumPerformanceData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  groupPeriod,
}) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod });
  const [llpData, llpLoading] = useLlpData({ from, to, groupPeriod });
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !llpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const llpItem = llpData[i];
      const volumeItem = volumeData[i];
      let apr =
        feeItem?.all && llpItem?.aum
          ? (feeItem.all / llpItem.aum) * 100 * 365 * dailyCoef
          : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage =
        volumeItem?.all && llpItem?.aum
          ? (volumeItem.all / llpItem.aum) * 100 * dailyCoef
          : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr =
      ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage =
      ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, llpData, volumeData]);

  return [data, feesLoading || llpLoading || volumeLoading];
}

export function useLlpData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const timestampProp = chainName === "mode_mainnet" ? "id" : "timestamp";
  const query = `{
    llpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdl
      llpSupply
      distributedUsd
      distributedEth
    }
  }`;
  let [data, loading, error] = useGraph(query, { chainName });

  let cumulativeDistributedUsdPerLlp = 0;
  let cumulativeDistributedEthPerLlp = 0;
  const llpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    let prevLlpSupply;
    let prevAum;

    let ret = sortBy(data.llpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdl) / 1e18;
        const llpSupply = Number(item.llpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerLlp = distributedUsd / llpSupply || 0;
        cumulativeDistributedUsdPerLlp += distributedUsdPerLlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerLlp = distributedEth / llpSupply || 0;
        cumulativeDistributedEthPerLlp += distributedEthPerLlp;

        const llpPrice = aum / llpSupply;
        const timestamp = parseInt(item[timestampProp]);

        const newItem = {
          timestamp,
          aum,
          llpSupply,
          llpPrice,
          cumulativeDistributedEthPerLlp,
          cumulativeDistributedUsdPerLlp,
          distributedUsdPerLlp,
          distributedEthPerLlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { llpSupply, aum } = item;
        if (!llpSupply) {
          llpSupply = prevLlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.llpSupplyChange = prevLlpSupply
          ? ((llpSupply - prevLlpSupply) / prevLlpSupply) * 100
          : 0;
        if (item.llpSupplyChange > 1000) item.llpSupplyChange = 0;
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) item.aumChange = 0;
        prevLlpSupply = llpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    return ret;
  }, [data]);

  return [llpChartData, loading, error];
}

export function useLlpPerformanceData(
  llpData,
  feesData,
  { from = FIRST_DATE_TS, chainName = "mode_mainnet" } = {}
) {
  const [btcPrices] = useCoingeckoPrices("BTC", { from });
  const [ethPrices] = useCoingeckoPrices("ETH", { from });
  // const [maticPrices] = useCoingeckoPrices("MATIC", { from });

  const llpPerformanceChartData = useMemo(() => {
    if (
      !btcPrices ||
      !ethPrices ||
      //!maticPrices ||
      !llpData ||
      !feesData
    ) {
      return null;
    }

    const llpDataById = llpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    let BTC_WEIGHT = 0;
    let ETH_WEIGHT = 0;

    if (chainName == "mode_mainnet") {
      BTC_WEIGHT = 0.25;
      ETH_WEIGHT = 0.25;
    } else {
      BTC_WEIGHT = 0.25;
      ETH_WEIGHT = 0.25;
    }

    const STABLE_WEIGHT = 1 - BTC_WEIGHT - ETH_WEIGHT;
    const LLP_START_PRICE =
      llpDataById[btcPrices[0].timestamp]?.llpPrice || 1.19;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;
   // const mode_mainnetFirstPrice = mode_mainnetPrices[0]?.value;
    //const avaxFirstPrice = maticPrices[0]?.value;

    let indexBtcCount = (LLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    let indexEthCount = (LLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    let indexStableCount = LLP_START_PRICE * STABLE_WEIGHT;

    const lpBtcCount = (LLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (LLP_START_PRICE * 0.5) / ethFirstPrice;
    //const lpAvaxCount = (LLP_START_PRICE * 0.5) / avaxFirstPrice;

    const ret = [];
    let cumulativeFeesPerLlp = 0;
    let cumulativeEsliqRewardsPerLlp = 0;
    let lastLlpPrice = 0;

    let prevEthPrice = 3400;
    let prevAvaxPrice = 1000;
    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      //const avaxPrice = maticPrices[i]?.value || prevAvaxPrice;
      //prevAvaxPrice = avaxPrice;
      prevEthPrice = ethPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      const llpItem = llpDataById[timestampGroup];
      const llpPrice = llpItem?.llpPrice ?? lastLlpPrice;
      lastLlpPrice = llpPrice;
      const llpSupply = llpDataById[timestampGroup]?.llpSupply;
      const dailyFees = feesDataById[timestampGroup]?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice +
        indexEthCount * ethPrice +
        //indexAvaxCount * avaxPrice +
        indexStableCount;

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice;
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT;
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + LLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(btcPrice / btcFirstPrice));
      const lpEthPrice =
        (lpEthCount * ethPrice + LLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(ethPrice / ethFirstPrice));
      // const lpAvaxPrice =
      //   (lpAvaxCount * avaxPrice + LLP_START_PRICE / 2) *
      //   (1 + getImpermanentLoss(avaxPrice / avaxFirstPrice));

      if (dailyFees && llpSupply) {
        const INCREASED_LLP_REWARDS_TIMESTAMP = 1635714000;
        const LLP_REWARDS_SHARE =
          timestampGroup >= INCREASED_LLP_REWARDS_TIMESTAMP ? 0.7 : 0.5;
        const collectedFeesPerLlp = (dailyFees / llpSupply) * LLP_REWARDS_SHARE;
        cumulativeFeesPerLlp += collectedFeesPerLlp;

        cumulativeEsliqRewardsPerLlp += (llpPrice * 0.8) / 365;
      }

      let llpPlusFees = llpPrice;
      if (llpPrice && llpSupply && cumulativeFeesPerLlp) {
        llpPlusFees = llpPrice + cumulativeFeesPerLlp;
      }

      let llpApr;
      let llpPlusDistributedUsd;
      let llpPlusDistributedEth;
      if (llpItem) {
        if (llpItem.cumulativeDistributedUsdPerLlp) {
          llpPlusDistributedUsd =
            llpPrice + llpItem.cumulativeDistributedUsdPerLlp;
          // llpApr = llpItem.distributedUsdPerLlp / llpPrice * 365 * 100 // incorrect?
        }
        if (llpItem.cumulativeDistributedEthPerLlp) {
          llpPlusDistributedEth =
            llpPrice + llpItem.cumulativeDistributedEthPerLlp * ethPrice;
        }
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        // lpAvaxPrice,
        llpPrice,
        btcPrice,
        ethPrice,
        llpPlusFees,
        llpPlusDistributedUsd,
        llpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        //indexAvaxCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((llpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: (
          (llpPlusFees / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedUsd: (
          (llpPlusDistributedUsd / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedEth: (
          (llpPlusDistributedEth / lpEthPrice) *
          100
        ).toFixed(2),

        performanceLpBtcCollectedFees: (
          (llpPlusFees / lpBtcPrice) *
          100
        ).toFixed(2),

        // performanceLpAvaxCollectedFees: (
        //   (llpPlusFees / lpAvaxPrice) *
        //   100
        // ).toFixed(2),

        performanceSynthetic: ((llpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: (
          (llpPlusFees / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedUsd: (
          (llpPlusDistributedUsd / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedEth: (
          (llpPlusDistributedEth / syntheticPrice) *
          100
        ).toFixed(2),

        llpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, llpData, feesData]);

  return [llpPerformanceChartData];
}

export function useTokenStats({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = "daily",
  chainName = "mode_mainnet",
} = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;

      const symbol = tokenSymbols[token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};
      let poolAmountUsdTemp = parseInt(stats.poolAmountUsd) / 1e30;
      if (poolAmountUsdTemp / 1e21 > 1) {
        poolAmountUsdTemp = poolAmountUsdTemp / 1e21;
      }
      memo[timestamp][symbol] = {
        poolAmountUsd: poolAmountUsdTemp,
      };
      return memo;
    }, {});

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}

export function useReferralsData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "mode_mainnet",
} = {}) {
  const query = `{
    globalStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      volume
      volumeCumulative
      totalRebateUsd
      totalRebateUsdCumulative
      discountUsd
      discountUsdCumulative
      referrersCount
      referrersCountCumulative
      referralCodesCount
      referralCodesCountCumulative
      referralsCount
      referralsCountCumulative
      timestamp
    }
  }`;
  const subgraph = "liq-referrals/1.0.0/gn";
  const [graphData, loading, error] = useGraph(query, { subgraph });

  const data = graphData
    ? sortBy(graphData.globalStats, "timestamp").map((item) => {
        const totalRebateUsd = item.totalRebateUsd / 1e30;
        const discountUsd = item.discountUsd / 1e30;
        return {
          ...item,
          volume: item.volume / 1e30,
          volumeCumulative: item.volumeCumulative / 1e30,
          totalRebateUsd,
          totalRebateUsdCumulative: item.totalRebateUsdCumulative / 1e30,
          discountUsd,
          referrerRebateUsd: totalRebateUsd - discountUsd,
          discountUsdCumulative: item.discountUsdCumulative / 1e30,
          referralCodesCount: parseInt(item.referralCodesCount),
          referralCodesCountCumulative: parseInt(
            item.referralCodesCountCumulative
          ),
          referrersCount: parseInt(item.referrersCount),
          referrersCountCumulative: parseInt(item.referrersCountCumulative),
          referralsCount: parseInt(item.referralsCount),
          referralsCountCumulative: parseInt(item.referralsCountCumulative),
        };
      })
    : null;

  return [data, loading, error];
}

export async function getStatsFromSubgraph(
  graphClient,
  chainName = "mode_mainnet"
) {
  const queryString = `{
    totalVolumes: volumeStats(where: {period: "total"}) {
      swap
      mint
      burn
      margin
      liquidation
    }
    deltaVolumes: volumeStats(
      first:1
      orderBy: id
      orderDirection: desc
      where: {period: "daily"}
    ) {
      swap
      mint
      burn
      margin
      liquidation
    }
  	totalFees: feeStats(where: {period: "total"}) {
      swap
      mint
      burn
      margin
      liquidation
		}
    deltaFees: feeStats(
      first:1
      orderBy: id
      orderDirection: desc
      where: {period: "daily"}
    ) {
      swap
      mint
      burn
      margin
      liquidation
    }
  }`;

  const query = gql(queryString);
  const { data } = await graphClient.query({ query });
  const statsProps = ["totalVolumes", "deltaVolumes", "totalFees", "deltaFees"];
  const methodProps = ["swap", "mint", "burn", "margin", "liquidation"];
  const result = {};
  //console.log(data);
  statsProps.forEach((statsProp) => {
    result[statsProp] = {};
    let total = 0;
    methodProps.forEach((methodProp) => {
      const statValue = parseInt(data[statsProp][0][methodProp]) / 1e30;
      //console.log(statValue);
      result[statsProp][methodProp] = statValue;
      total += statValue;
    });
    result[statsProp].total = total;
  });
  //console.log(result);
  return result;
}
export async function getUniqueUserCounts(
  graphClient,
  chainName = "mode_mainnet"
) {
  const queryString = `{
   userStats(where: {id:"total"}){
    uniqueCount
    period
  }
}`;

  const query = gql(queryString);
  const { data } = await graphClient.query({ query });
  return {
    totalUsers: data.userStats[0].uniqueCount,
  };
}

export async function getActivePositionsFromSubgraph(
  graphClient,
  chainName = "mode_mainnet"
) {
  const queryString = `{
    activePositions: activePositions {
      id
		}
  }`;

  const query = gql(queryString);
  const { data } = await graphClient.query({ query });
  return {
    totalActivePositions: data.activePositions.length,
  };
}
export async function getPositionStatsFromSubgraph(
  graphClient,
  chainName = "mode_mainnet"
) {
  const queryString = `{
    positionStats: tradingStats(where: {id: "total"}) {
      id
      longOpenInterest
      shortOpenInterest
		}
  }`;

  const query = gql(queryString);
  const { data } = await graphClient.query({ query });
  const statsProps = ["positionStats"];
  const methodProps = ["longOpenInterest", "shortOpenInterest"];
  let result = {};
  // console.log(data);
  statsProps.forEach((statsProp) => {
    result = {};
    let total = BigNumber.from(0);
    methodProps.forEach((methodProp) => {
      const statValue = BigNumber.from(data[statsProp][0][methodProp]);
      //console.log(statValue);
      result[methodProp] = statValue;
      total = total.add(statValue);
    });
    result.openInterest = ethers.utils
      .parseUnits(total.toString(), 0)
      .toString();
  });
  //console.log(result);
  result.longOpenInterest = ethers.utils
    .parseUnits(result.longOpenInterest.toString(), 0)
    .toString();
  result.shortOpenInterest = ethers.utils
    .parseUnits(result.shortOpenInterest.toString(), 0)
    .toString();
  return result;
}
