export const MODE_MAINNET = 34443;
export const addresses = {
  [MODE_MAINNET]: {
    ETH: "0x4200000000000000000000000000000000000006",
    BTC: "0xcDd475325D6F564d27247D1DddBb0DAc6fA0a5CF",
    RewardReader: "0x85A3151dA1EC0EA86edbd746AB6160d2c38a502A",
    LLP: "0xd0dE0B899cDDEd68beF186C5637a38c4a95DFF39",
    LlpManager: "0x2256F5064C04e67c0BF4002f2f559A3FD52A5524",
  },
};

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
